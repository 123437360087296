// DropDownMenu.js
import React, { useState } from "react";
import "./DropDownMenu.css";

const DropDownMenu = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<li className="dropdown" onMouseLeave={() => setIsOpen(false)}>
			<span
				className="nav-link"
				onClick={() => {
					setIsOpen(!isOpen);
				}}
			>
				{title}
			</span>
			{isOpen && <ul className="dropdown-content">{children}</ul>}
		</li>
	);
};

export default DropDownMenu;
