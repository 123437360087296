import "./Testyourself.css";
import WordsDipalyed from "../../components/testing/wordsDisplayed/WordsDisplayed";
import TestYourselfButtons from "../../components/testing/controls/testYourselfButtons/TestYourselfButtons";
import RecordAudioControls from "../../components/testing/controls/recordAudioControls/RecordAudioControls";
import TestingOptions from "../../components/testing/controls/testingOptions/TestingOptions";

import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../utils/context/UserContext";
import { useQuery, useMutation } from "@apollo/client";
import { PHRASEBYUSER } from "../../data/queries";
import { Storage } from "aws-amplify";
import { UPDATEPHRASE } from "../../data/mutations";

const Testyourself = () => {
	const [testLang, setTestLang] = useState(false);
	const [randNumb, setRandNumb] = useState(null);
	const [showAnswer, setShowAnswer] = useState(false);
	const [phrase, setPhrase] = useState("");
	const [newWordClicked, setNewWordClicked] = useState(false);
	const [fetchedAudioFile, setFetchedAudioFile] = useState(null);
	const [recordedAudioFile, setRecordedAudioFile] = useState(null);
	const [audioFileName, setAudioFileName] = useState(null);
	const [autoPlayAudio, setAutoPlayAudio] = useState(null);
	const [updatePhrase] = useMutation(UPDATEPHRASE);
	const { user, userLoading } = useContext(UserContext);

	const { username } = user || {};

	const {
		loading: queryLoading,
		error: queryError,
		data: queryData,
	} = useQuery(PHRASEBYUSER, {
		variables: { username: username || "" },
		skip: !username,
	});

	console.log("Data", queryData);

	useEffect(() => {
		if (newWordClicked) {
			getNewWordHandler();
		}
	}, [newWordClicked]);

	useEffect(() => {
		if (audioFileName) {
			retrieveAudio();
		}
	}, [audioFileName]);

	useEffect(() => {
		if (phrase) {
			setAudioFileName(phrase + ".webm");
		}
	}, [phrase]);

	if (userLoading || queryLoading) return "Loading...";
	if (queryError) return `Error! ${queryError.message}`;

	let length = queryData.phraseByUser.items.length;

	function getNewWordHandler() {
		setShowAnswer(false);
		setRecordedAudioFile(null);
		const randomNumber = Math.floor(Math.random() * Math.floor(length));
		setRandNumb(randomNumber);
		getPhraseByRandommNumber(randomNumber);
	}

	function getPhraseByRandommNumber(randomNumber) {
		queryData.phraseByUser.items.map((phrase, idx) => {
			if (idx === randomNumber) {
				setPhrase(phrase.id);
				console.log("PhraseID", phrase.id);
			}
		});
	}

	const updateDatabase = async (audioFile, ID) => {
		console.log(`updateDatabase Audio File, ${audioFile} ID= ${ID}`);
		try {
			updatePhrase({
				variables: {
					input: {
						id: ID,
						S3AudioFileName: ID,
					},
				},
			});
		} catch {
			console.log("Was not able to save, please try again");
		}
	};

	const saveAudioToS3 = async (file, filename) => {
		console.log("Save to S3 filename ", filename);
		console.log("Save to S3 audio ", file);

		const audioBlob = await fetch(file).then((recording) => {
			console.log("Save to S3 recording ", recording);
			return recording.blob();
		});
		try {
			const result = Storage.put(filename, audioBlob, {
				level: "private",
				contentType: "audio/webm",
				resumable: true,
				completeCallback: (event) => {
					console.log("Files saved: ", result);
					updateDatabase(filename, phrase);
					alert("Audio Saved!");
				},
				progressCallback: (progress) => {
					console.log(
						`Uploaded: ${progress.loaded}/${progress.total}`
					);
				},
				errorCallback: (err) => {
					console.error("Unexpected error while uploading", err);
					// setRecording(false);
				},
			});
		} catch (error) {
			console.log("Error uploading file: ", error);
		}
		setRecordedAudioFile(null);
	};

	const retrieveAudio = async () => {
		console.log("retrieveAudio filename get", audioFileName);
		try {
			const result = await Storage.get(audioFileName, {
				level: "private",
				contentType: "audio/webm",
				download: true,
				progressCallback: (progress) => {
					console.log(
						`Download Size: ${progress.loaded}/${progress.total}`
					);
				},
			});
			console.log(`Found Audio File`);
			const audioURL = URL.createObjectURL(result.Body);
			setFetchedAudioFile(audioURL);
		} catch (error) {
			console.error("Error retrieving audio:", error);
			setFetchedAudioFile(null);
		}
	};

	return (
		<>
			{/* ######################### New words and answers ######################### */}
			<section className="test-yourself-section">
				<WordsDipalyed
					data={queryData}
					randNumb={randNumb}
					testLang={testLang}
					showAnswer={showAnswer}
				/>

				<div className="controls-section">
					{/* <div className="controls-wrapper"> */}
					{/* ####################### Get words buttons ####################### */}
					<TestYourselfButtons
						setFetchedAudioFile={setFetchedAudioFile}
						setNewWordClicked={setNewWordClicked}
						newWordClicked={newWordClicked}
						setShowAnswer={setShowAnswer}
						recordedAudioFile={recordedAudioFile}
						saveAudioToS3={saveAudioToS3}
						setRecordedAudioFile={setRecordedAudioFile}
						audioFileName={audioFileName}
						showAnswer={showAnswer}
						fetchedAudioFile={fetchedAudioFile}
					/>
					{/* ####################### Audio Controls ####################### */}

					<RecordAudioControls
						recordedAudioFile={recordedAudioFile}
						fetchedAudioFile={fetchedAudioFile}
						audioFileName={audioFileName}
						showAnswer={showAnswer}
						autoPlayAudio={autoPlayAudio}
					/>

					{/* ####################### Options ####################### */}
					<TestingOptions
						setTestLang={setTestLang}
						testLang={testLang}
						autoPlayAudio={autoPlayAudio}
						setAutoPlayAudio={setAutoPlayAudio}
					/>
					{/* </div> */}
				</div>
			</section>
		</>
	);
};

export default Testyourself;
