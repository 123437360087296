import * as React from "react";
export interface UserContextType {
   user: {
      username: string;
      // other properties of the user object
   };
   setUser: (user: { username: string }) => void;
   userLoading: boolean;
   authState: string;
   setAuthState: (authState: string) => void;
   // other properties of the context
}

export const UserContext = React.createContext<UserContextType | undefined>(undefined);
