import "./Home.css";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../utils/context/UserContext";

const Home = () => {
	const { user, setUser, authState, setAuthState } = useContext(UserContext);
	const history = useHistory();

	useEffect(() => {
		console.log("authState", authState);
		console.log("user", user);
	}, [authState, user]);

	function signUpNewUser() {
		console.log("This is history", history);
		// history.push("/signup");
	}

	return (
		<div className="home">
			<h1>Bienvenidos!</h1>
			<p style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
				Welcome to RandomSpanish where you can learn Spanish for FREE!
				Please signup if you don't have an account to unlock all the
				functionality Random Spanish has to offer!
			</p>

			<button
				className="btn"
				onClick={() => {
					signUpNewUser();
				}}
			>
				Sign up today!
			</button>

			{/* <Authenticator initialState="signUp">
				{({ signOut, user }) => (
					<main>
						<h1>Hello {user.username}</h1>
						<button onClick={signOut}>Sign out</button>
					</main>
				)}
			</Authenticator> */}
		</div>
	);
};

export default Home;
