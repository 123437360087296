/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from "@apollo/client";

export const getPhrase = /* GraphQL */ `
	query GetPhrase($id: ID!) {
		getPhrase(id: $id) {
			id
			user
			english
			spanish
			is_deleted
			createdAt
			updatedAt
		}
	}
`;
export const listPhrases = /* GraphQL */ `
	query ListPhrases(
		$filter: ModelPhraseFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPhrases(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				user
				english
				spanish
				is_deleted
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
export const PHRASEBYUSER = /* GraphQL */ gql`
	query PhraseByUser($username: String!) {
		phraseByUser(user: $username) {
			items {
				id
				user
				english
				spanish
				S3AudioFileName
			}
			nextToken
		}
	}
`;
export const updateDeleteById = /* GraphQL */ `
	query UpdateDeleteById(
		$is_deleted: String
		$sortDirection: ModelSortDirection
		$filter: ModelPhraseFilterInput
		$limit: Int
		$nextToken: String
	) {
		updateDeleteById(
			is_deleted: $is_deleted
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				user
				english
				spanish
				is_deleted
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;
