/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { gql } from "@apollo/client";

export const CREATEPHRASE = /* GraphQL */ gql`
	mutation CreatePhrase(
		$user: String!
		$id: ID
		$english: String!
		$spanish: String!
	) {
		createPhrase(
			input: {
				id: $id
				user: $user
				english: $english
				spanish: $spanish
				is_deleted: "false"
			}
		) {
			id
			user
			english
			spanish
			S3AudioFileName
			is_deleted
			createdAt
			updatedAt
		}
	}
`;
export const UPDATEPHRASE = /* GraphQL */ gql`
	mutation UpdatePhrase(
		$input: UpdatePhraseInput!
		$condition: ModelPhraseConditionInput
	) {
		updatePhrase(input: $input, condition: $condition) {
			id
			user
			english
			spanish
			S3AudioFileName
			is_deleted
			createdAt
			updatedAt
		}
	}
`;
export const DELETEPHRASE = /* GraphQL */ gql`
	mutation DeletePhrase($id: ID!) {
		deletePhrase(input: { id: $id }) {
			english
			id
			is_deleted
			spanish
			user
		}
	}
`;
