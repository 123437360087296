import "./AudioPlayer.css";
import React, { useEffect, useRef } from "react";

const AudioPlayer = ({ autoPlayAudio, currentRecording, showAnswer }) => {
	const audioRef = useRef(null);

	useEffect(() => {
		console.log("Auto play", autoPlayAudio);
		if (audioRef.current) {
			if (autoPlayAudio && showAnswer) {
				audioRef.current.play();
			} else {
				audioRef.current.pause();
			}
		}
	}, [showAnswer, autoPlayAudio]);

	return (
		<div id="audioRecorder">
			{currentRecording ? (
				<audio ref={audioRef} controls src={currentRecording} />
			) : (
				<div className="disabled-audio">No audio available</div>
			)}
		</div>
	);
};

export default AudioPlayer;
