import React from "react";
import "./TestingOptions.css";
const TestingOptions = ({
	setTestLang,
	testLang,
	autoPlayAudio,
	setAutoPlayAudio,
}) => {
	return (
		<>
			<div className="testing-options-section">
				<div controlId="formBasicCheckbox">
					<div
						style={{ color: "white", fontSize: "16px" }}
						type="checkbox"
						label="Test in Spanish"
						onChange={() => {
							setTestLang(!testLang);
						}}
					/>
				</div>
				<div className="form-check">
					<input
						className="form-check-input"
						type="checkbox"
						value=""
						id="flexCheckIndeterminate"
						onChange={() => {
							setTestLang(!testLang);
						}}
					></input>
					<label
						class="form-check-label"
						style={{ color: "black", fontSize: "1rem" }}
						for="flexCheckIndeterminate"
					>
						Test in Spanish
					</label>
				</div>
				{/* <div controlId="formBasicCheckbox">
					<div
						style={{ color: "white", fontSize: "16px" }}
						type="checkbox"
						label="Test in Spanish"
						onChange={() => {
							setTestLang(!testLang);
						}}
					/>
				</div> */}
				<div className="form-check">
					<input
						className="form-check-input"
						type="checkbox"
						value=""
						id="flexCheckIndeterminate"
						onChange={() => {
							console.log("Not implemented yet");
							setAutoPlayAudio(!autoPlayAudio);
						}}
					></input>
					<label
						class="form-check-label"
						style={{ color: "black", fontSize: "1rem" }}
						for="flexCheckIndeterminate"
					>
						Auto play audio
					</label>
				</div>
			</div>
		</>
	);
};

export default TestingOptions;
