// NavBarItem.js
import React from "react";
// import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import "./NavBarItem.css";

const NavBarItem = ({ children, to }) => {
	if (!children) {
		return null;
	}

	return (
		<li className="nav-item">
			<NavLink
				to={to}
				activeStyle={{
					textDecoration: "underline",
				}}
				className="nav-link"
			>
				{children}
			</NavLink>
		</li>
	);
};

export default NavBarItem;
