// import React from "react";
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Hub } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

const Authevents = ({ setAuthState, authState }) => {
	// const [nextAuthOperation, setNextAuthOperation] = useState(null);
	const { user, setUser } = useContext(UserContext);

	useEffect(() => {
		onAuthUIStateChange((nextAuthState, authData) => {
			setAuthState(nextAuthState);
			if (authData && authData.username) {
				setUser({ username: authData.username });
			} else {
				setUser({ username: "" });
			}
			console.log("AuthEvents authData", authData);
		});
	}, []);

	const listener = (data) => {
		switch (data.payload.event) {
			case "signIn":
				setAuthState(data.payload.data.message);
				break;
			case "signUp":
				setAuthState(data.payload.data.message);
				break;
			case "signOut":
				// console.log("AUTHEVENTS.js - signedOut");
				setAuthState(data.payload.data.message);
				break;
			case "signIn_failure":
				// console.log("signIn_failure FAIL!!!! --", data.payload.data.message);
				setAuthState(data.payload.data.message);

				break;
			case "signUp_failure":
				// console.log("SignUp FAIL!!!! --", data.payload.data.message);
				setAuthState(data.payload.data.message);
				break;
				//   case "tokenRefresh":
				//     (data.payload.data.message);

				break;
			case "tokenRefresh_failure":
				setAuthState(data.payload.data.message);

				break;
			case "configured":
				setAuthState(data.payload.data.message);
		}
	};

	Hub.listen("auth", listener);

	return null;
};

export default Authevents;
