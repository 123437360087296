import "./AddWords.css";
import React, { useState, useContext } from "react";
import { UserContext } from "../../utils/context/UserContext";
import { useMutation } from "@apollo/client";
import { CREATEPHRASE } from "../../data/mutations";
import GoogleTranslate from "../../utils/googleTranslate/GoogleTranslate";

//==============================================================================
const AddWords = () => {
	const [language, setLanguage] = useState(0);
	const [inputText, setInputText] = useState("");
	const [translatedOutputText, setTranslatedOutputText] = useState("");
	const { user } = useContext(UserContext);
	const [createPhrase, { loading: mutationLoading, error: mutationError }] =
		useMutation(CREATEPHRASE);

	// const currentUserName = user.username;
	// console.log("user", user);
	// console.log("currentUserName", currentUserName);

	const options = [
		{
			startLang: "English",
			transLang: "Spanish",
			value: "es",
		},
		{
			startLang: "Spanish",
			transLang: "English",
			value: "en",
		},
	];

	function uuidv4() {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
			(
				c ^
				(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
			).toString(16)
		);
	}

	function swap() {
		language == 0 ? setLanguage(1) : setLanguage(0);
	}

	//***************** Save to Database *****************
	const saveToDatabase = async () => {
		if (inputText && translatedOutputText) {
			let en;
			let es;

			language === 0 ? (en = inputText) : (en = translatedOutputText);
			language === 1 ? (es = inputText) : (es = translatedOutputText);

			try {
				// console.log("Hello from username ", user.username);
				createPhrase({
					variables: {
						id: uuidv4(),
						user: user.username,
						english: en,
						spanish: es,
						is_deleted: "false",
					},
				});

				setInputText("");
				setTranslatedOutputText("");
			} catch {
				alert("Was not able to save, please try again");
			}
		} else {
			alert("please enter text first");
		}
	};

	return (
		<>
			{!user ? null : (
				<section className="translate-section">
					<GoogleTranslate
						language={language}
						text={inputText}
						setTranslated={setTranslatedOutputText}
					/>
					<form className="form-left">
						<fieldset>
							<legend>{options[language].startLang}</legend>
							<div className="input-group mb-3">
								<textarea
									className="form-control"
									placeholder="English phrase"
									value={inputText}
									onChange={(e) =>
										setInputText(e.target.value)
									}
								/>
							</div>
						</fieldset>
					</form>

					<form className="form-right">
						<fieldset>
							<legend>{options[language].transLang}</legend>
							<div className="input-group mb-3">
								<textarea
									className="form-control"
									placeholder="Translated phrase"
									value={translatedOutputText}
								/>
							</div>
						</fieldset>
					</form>

					<div className="button-section">
						<button
							className="btn"
							onClick={() => {
								saveToDatabase();
							}}
						>
							Save
						</button>
						<button
							className="btn"
							onClick={() => {
								swap();
							}}
						>
							Swap
						</button>

						<div className="displayMessages">
							{mutationError && <p>Error :( Please try again</p>}
							{mutationLoading && <h3>Saved</h3>}
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default AddWords;
