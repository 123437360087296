import React from "react";
import "./WordsDisplayed.css";
const WordsDipalyed = ({ data, randNumb, testLang, showAnswer }) => {
	return (
		<>
			<section className="test-yourself-section__left">
				{data.phraseByUser.items.map((phrase, idx) => {
					if (idx === randNumb) {
						let testPhrase = phrase.english;
						let answerPhrase = phrase.spanish;
						{
							testLang === false
								? (testPhrase = phrase.english)
								: (testPhrase = phrase.spanish);
						}
						{
							testLang === true
								? (answerPhrase = phrase.english)
								: (answerPhrase = phrase.spanish);
						}

						return <h3>{testPhrase}</h3>;
					}
				})}
			</section>

			<section className="test-yourself-section__right">
				<>
					{data.phraseByUser.items.map((phrase, idx) => {
						if (idx === randNumb) {
							let testPhrase = phrase.english;
							let answerPhrase = phrase.spanish;

							testLang === false
								? (testPhrase = phrase.english)
								: (testPhrase = phrase.spanish);
							testLang === true
								? (answerPhrase = phrase.english)
								: (answerPhrase = phrase.spanish);

							return (
								<>
									{!showAnswer ? null : (
										<h3>{answerPhrase}</h3>
									)}
								</>
							);
						}
					})}
				</>
			</section>
		</>
	);
};

export default WordsDipalyed;
