// import "../styles/Pages.css";
import "./Lessons.css";
import React, { useState, useEffect } from "react";
import Pagination from "../../components/pagination/Pagination";
import { jsonWordsLists } from "../../data/words";
import Words from "../../components/GetStaticWords";

const CommonVerbs = () => {
	const [words, setWords] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [wordsPerPage] = useState(10);

	// console.log("json lists: ", jsonWordsLists);

	useEffect(() => {
		setWords(jsonWordsLists);
		// console.log("these are my words ", words);
	}, []);

	const indexOfLastPost = currentPage * wordsPerPage;
	const indexOfFirstPost = indexOfLastPost - wordsPerPage;
	const currentPosts = words.slice(indexOfFirstPost, indexOfLastPost);

	const paginate = (pageNumber) => {
		// console.log("this is the current page number, ", pageNumber);

		setCurrentPage(pageNumber);
		// console.log("This is the page number ", pageNumber);
	};

	return (
		<section className="list-of-words-common-verbs">
			<div className="list-of-words-common-verbs">
				{/* <Words words={currentPosts} />
				<Pagination
					wordsPerPage={wordsPerPage}
					totalWords={jsonWordsLists.length}
					paginate={paginate}
				/> */}
				<h2>Coming Soon</h2>
			</div>
		</section>
	);
};

export default CommonVerbs;
