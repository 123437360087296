import "./modal.css";
import React, { useState, useEffect, useContext } from "react";
import { withAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../utils/context/UserContext";

const LoginModal = () => {
	// const [show, setShow] = useState(false);
	//   const { user } = useContext(UserContext);
	const history = useHistory();
	const { user, setUser, authState, setAuthState } = useContext(UserContext);

	useEffect(() => {
		if (user) {
			history.push("/testyourself");
		}
	}, [user]);

	return (
		<>
			<div className="modal-container">
				{authState == "signedOut" && (
					<div>
						<AmplifySignIn headerText="Sign In" />
					</div>
				)}
				{authState != "signedOut" && (
					<div>
						<AmplifySignIn headerText={authState} />
					</div>
				)}
			</div>
		</>
	);
};

export default withAuthenticator(LoginModal);
