import "./UsersPhrases.css";
import React, { useState, useContext, useEffect } from "react";
import Pagination from "../../components/pagination/Pagination";
import { UserContext } from "../../utils/context/UserContext";
import { useQuery, useMutation } from "@apollo/client";
import { PHRASEBYUSER } from "../../data/queries";
import { DELETEPHRASE } from "../../data/mutations";
import { Storage } from "aws-amplify";

//==============================================================================
const UserPhrases = () => {
	// const userContext = useContext(UserContext);
	const { user, userLoading } = useContext(UserContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [phrases, setPhrases] = useState([]);
	const [phrasesPerPage] = useState(7);
	const [deletePhrase] = useMutation(DELETEPHRASE);

	const { username } = user || {};

	const {
		loading: queryLoading,
		error: queryError,
		data: data,
	} = useQuery(PHRASEBYUSER, {
		variables: { username: username || "" },
		skip: !username,
	});

	useEffect(() => {
		if (data) {
			console.log("Data", data);
			setPhrases(data.phraseByUser.items);
		}
	});

	if (userLoading || queryLoading) return "Loading...";
	if (queryError) return `Error! ${queryError.message}`;

	async function deleteItem(myid) {
		try {
			// Delete the audio file from S3
			await Storage.remove(`${myid}.webm`, { level: "private" });
			console.log("Sucessfully delete item from S3");
		} catch (error) {
			console.error("Error deleting audio file from S3:", error);
		}

		try {
			await deletePhrase({
				variables: { id: myid },
				refetchQueries: [
					{
						query: PHRASEBYUSER,
						variables: { username: username || "" },
					},
				],
				awaitRefetchQueries: true,
			});
			console.log("Sucessfully delete item from DB");
		} catch {
			alert("Was not able to delete this item");
		}
	}

	const indexOfLastPhrase = currentPage * phrasesPerPage;
	const indexOfFirstPhrase = indexOfLastPhrase - phrasesPerPage;
	const currentPhrases = phrases.slice(indexOfFirstPhrase, indexOfLastPhrase);

	const paginate = (pageNumber) => {
		// console.log("paginating...", pageNumber);
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<section className="list-of-user-phrases-section">
				<table className="table">
					<thead>
						<th>English</th>
						<th>Spanish</th>
						<th>Delete</th>
						<th>Audio File</th>
					</thead>
					<tbody>
						{currentPhrases.map((phrase) => {
							return (
								<tr>
									<td>{phrase.english}</td>
									<td>{phrase.spanish}</td>
									<td className="button-cell">
										<button
											className="btn btn-delete"
											onClick={() => {
												{
													deleteItem(phrase.id);
												}
											}}
										>
											Delete
										</button>
									</td>
									<td>
										{phrase.S3AudioFileName !== null
											? "Yes"
											: "No"}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<Pagination
					wordsPerPage={phrasesPerPage}
					totalWords={data.phraseByUser.items.length}
					paginate={paginate}
				/>
			</section>
		</>
	);
};

export default UserPhrases;
