import React, { useState, useEffect } from "react";
import axios from "axios";

const GoogleTranslate = ({ language, text, setTranslated }) => {
	const [debouncedText, setDebouncedText] = useState(text);

	const options = [{ value: "es" }, { value: "en" }];

	useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedText(text);
		}, 500);

		return () => {
			clearTimeout(timerId);
		};
	}, [text]);

	useEffect(() => {
		// console.log("do translation");
		const doTranslation = async () => {
			const { data } = await axios.post(
				"https://translation.googleapis.com/language/translate/v2",
				{},
				{
					params: {
						q: debouncedText,
						target: options[language].value,
						key: process.env.REACT_APP_TRANSLATION_KEY,
					},
				}
			);

			setTranslated(data.data.translations[0].translatedText);
		};
		doTranslation();
	}, [language, debouncedText]);

	return null;
};

export default GoogleTranslate;
