import "./RecordAudioControls.css";
import React, { useEffect, useState } from "react";
import AudioPlayer from "./audioPlayer/AudioPlayer";

const RecordAudioControls = ({
	recordedAudioFile,
	fetchedAudioFile,
	audioFileName,
	showAnswer,
	autoPlayAudio,
}) => {
	const [currentRecording, setCurrentRecording] = useState(null);
	const [internalAudioFileName, setInternalAudioFileName] = useState(null);

	useEffect(() => {
		console.log("RecordAudioControls recordedAudioFile", recordedAudioFile);
		if (audioFileName) {
			setInternalAudioFileName(audioFileName);
		}
		if (fetchedAudioFile || recordedAudioFile) {
			// console.log("RecordAudioControls UseEffect SetState");
			setCurrentRecording(recordedAudioFile || fetchedAudioFile);
		} else {
			// console.log("RecordAudioControls UseEffect SetState null");
			setCurrentRecording(null);
		}
	}, [recordedAudioFile, fetchedAudioFile]);

	useEffect(() => {
		// console.log("Update current recording");
	}, [currentRecording]);

	useEffect(() => {
		console.log("showAnswer", showAnswer);
	}, [showAnswer]);

	return (
		<>
			<div className="record-audio-buttons">
				<AudioPlayer
					autoPlayAudio={autoPlayAudio}
					currentRecording={currentRecording}
					showAnswer={showAnswer}
				/>
			</div>
		</>
	);
};

export default RecordAudioControls;
