import "./Main.css";
import "../../styles/Buttons.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import Home from "../../pages/Home/Home";
import AddWords from "../../pages/AddWords/AddWords";
import Lessons from "../../pages/Lessons/Lessons";
import Testyourself from "../../pages/Testyourself/Testyourself";
import UsersPhrases from "../../pages/UsersPhrases/UsersPhrases";
import Authevents from "../auth/Authevents";
import LoginModal from "../../components/modal/LoginModal";
// import SignupModal from "../../components/modal/SignupModal";
import { UserContext } from "../context/UserContext";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

import { Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";

const client = new ApolloClient({
	uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
	cache: new InMemoryCache(),
	headers: {
		"X-Api-Key": process.env.REACT_APP_APPSYNC_API_KEY,
	},
});

function App() {
	const [authState, setAuthState] = useState("signedOut");
	const [user, setUser] = useState(null);
	const [userLoading, setUserLoading] = useState(true);

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const userData = await Auth.currentAuthenticatedUser();
				setUser(userData);
				setUserLoading(false);
			} catch (error) {
				console.error("Error fetching user:", error);
				setUserLoading(false);
			}
		};

		fetchUser();
	}, []);

	return (
		<ApolloProvider client={client}>
			<div className="App">
				<UserContext.Provider
					value={{
						user,
						setUser,
						userLoading,
						authState,
						setAuthState,
					}}
				>
					<Authevents
						setAuthState={setAuthState}
						authState={authState}
					/>
					<BrowserRouter>
						<Navbar />
						<Route path="/" exact render={() => <Home />} />
						<Route
							path="/addwords"
							exact
							render={() => <AddWords />}
						/>
						<Route
							path="/lessons"
							exact
							render={() => <Lessons />}
						/>
						<Route
							path="/testyourself"
							exact
							render={() => <Testyourself />}
						/>
						<Route
							path="/words"
							exact
							render={() => <UsersPhrases />}
						/>
						{/* <Route
							path="/signup"
							exact
							render={() => <SignupModal />}
						/> */}
						<Route
							path="/signin"
							exact
							render={() => <LoginModal />}
						/>
					</BrowserRouter>
				</UserContext.Provider>
				<Footer className="Footer" />
			</div>
		</ApolloProvider>
	);
}

export default withAuthenticator(App);
