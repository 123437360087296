import "./navbar.css";
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../utils/context/UserContext";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import DropDownMenu from "./dropDownMenu/DropDownMenu";
import NavBarItem from "./navbarItem/NavBarItem";

const Navbar = () => {
	const { user, setUser, authState } = useContext(UserContext);
	const history = useHistory();

	let greetBrandItem = user ? "Welcome " + user.username + "!" : " ";
	const [sidebar, setSidebar] = useState(false);
	const [headings, setHeadings] = useState([""]);

	const showSidebar = () => {
		setSidebar(!sidebar);
	};

	const handleSignOut = async () => {
		history.push("/");
		await Auth.signOut();
		setUser(null);
	};

	return (
		<>
			<div className="above-nav-border-section"></div>
			<nav>
				{user ? (
					<div className="in-nav-logo">{`Wecome ${user.username}!`}</div>
				) : (
					<div className="in-nav-logo"></div>
				)}
				<ul className={sidebar ? "nav-links nav-active" : "nav-links"}>
					{!user ? (
						<>
							<NavBarItem to={"/"}>Home</NavBarItem>
							<NavBarItem to={"/"}>About</NavBarItem>
							<NavBarItem to={"/"}>Contact</NavBarItem>
						</>
					) : (
						<>
							<NavBarItem to={"/addwords"}>Add Words</NavBarItem>
							<NavBarItem to={"/testyourself"}>
								Test Yourself
							</NavBarItem>
							<NavBarItem to={"/words"}>User</NavBarItem>
							{/* <NavBarItem></NavBarItem> */}
							<DropDownMenu title="Lessons">
								<NavBarItem to={"/lessons"}>
									Alphabet
								</NavBarItem>
								<NavBarItem to={"/lessons"}>Verbs</NavBarItem>
							</DropDownMenu>
						</>
					)}

					{user ? (
						<li
							className="nav-item"
							onClick={() => {
								setSidebar(!sidebar);
							}}
						>
							<button
								className="btn btn-logout"
								onClick={handleSignOut}
							>
								Log Out
							</button>
						</li>
					) : (
						<li
							className="nav-item"
							onClick={() => {
								setSidebar(!sidebar);
							}}
						>
							<button
								className="btn btn-login"
								onClick={() => {
									history.push("/signin");
								}}
							>
								Sign in
							</button>
						</li>
					)}
				</ul>
				<div
					className={sidebar ? "burger toggle" : "burger"}
					onClick={showSidebar}
				>
					<div className="line1"></div>
					<div className="line2"></div>
					<div className="line3"></div>
				</div>
			</nav>
			<div className="below-nav-border-section"></div>
		</>
	);
};

export default Navbar;
