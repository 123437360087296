import "./Pagination.css";
import React from "react";

const Pagination = ({ wordsPerPage, totalWords, paginate }) => {
	const pageNumbers = [];

	let activeClass =
		paginate === undefined || paginate === null || paginate === 1
			? "active"
			: "";

	console.log("paginate ", paginate);
	// console.log("inside pag totalWords ", totalWords);

	for (let i = 1; i <= Math.ceil(totalWords / wordsPerPage); i++) {
		pageNumbers.push(i);
	}
	return (
		<div className="pagination">
			{pageNumbers.map((number) => (
				<li key={number} className="page-item">
					<button
						type="button"
						onClick={() => {
							console.log("this is the page number ", number);

							paginate(number);
						}}
						className={`page-link ${
							number === 1 ? activeClass : ""
						}`}
						// className="page-link"
					>
						{number}
					</button>
				</li>
			))}
		</div>
	);
};

export default Pagination;
