import "./TestYourselfButtons.css";
import React, { useRef, useContext, useState, useEffect } from "react";
import { UserContext } from "../../../../utils/context/UserContext";
const TestYourselfButtons = ({
	setFetchedAudioFile,
	setNewWordClicked,
	newWordClicked,
	setShowAnswer,
	recordedAudioFile,
	saveAudioToS3,
	setRecordedAudioFile,
	audioFileName,
	showAnswer,
	fetchedAudioFile,
}) => {
	const [currentRecording, setCurrentRecording] = useState(null);
	const [recording, setRecording] = useState(false);
	const [internalAudioFileName, setInternalAudioFileName] = useState(null);
	const { user } = useContext(UserContext);

	const recorder = useRef(null);

	useEffect(() => {
		console.log("RecordAudioControls recordedAudioFile", recordedAudioFile);
		if (audioFileName) {
			setInternalAudioFileName(audioFileName);
		}
		if (fetchedAudioFile || recordedAudioFile) {
			// console.log("RecordAudioControls UseEffect SetState");
			setCurrentRecording(recordedAudioFile || fetchedAudioFile);
		} else {
			// console.log("RecordAudioControls UseEffect SetState null");
			setCurrentRecording(null);
		}
	}, [recordedAudioFile, fetchedAudioFile]);

	useEffect(() => {
		// console.log("Update current recording");
	}, [currentRecording]);

	useEffect(() => {
		console.log("showAnswer", showAnswer);
	}, [showAnswer]);

	const startRecording = async () => {
		const stream = await navigator.mediaDevices.getUserMedia({
			audio: true,
		});
		const mediaRecorder = new MediaRecorder(stream);
		mediaRecorder.addEventListener("dataavailable", (event) => {
			const audioBlob = new Blob([event.data], { type: "audio/webm" });
			const url = URL.createObjectURL(audioBlob);
			setRecordedAudioFile(url);
			setRecording(false);
		});
		recorder.current = mediaRecorder;
		mediaRecorder.start();
		setRecording(true);
	};

	const stopRecording = () => {
		recorder.current.stop();
		setRecording(false);
	};

	return (
		<>
			<div className="button-section">
				{!user ? null : newWordClicked ? (
					<button
						className="btn btn-testyourself"
						onClick={() => {
							setShowAnswer(true);
							setNewWordClicked(false);
						}}
					>
						Answer
					</button>
				) : (
					<button
						className="btn btn-testyourself"
						onClick={() => {
							setFetchedAudioFile(null);
							setNewWordClicked(true);
						}}
					>
						New Word
					</button>
				)}

				<button
					className="btn"
					onClick={() => {
						if (recordedAudioFile) {
							console.log("recordedAudioFile", currentRecording);
							console.log("audioFileName", internalAudioFileName);
							if (
								currentRecording !== null &&
								internalAudioFileName !== null
							) {
								saveAudioToS3(
									currentRecording,
									internalAudioFileName
								);
							}
						} else if (recording) {
							stopRecording();
						} else {
							setFetchedAudioFile(null);
							startRecording();
						}
					}}
					disabled={!showAnswer}
				>
					<div className="button-content">
						<div class={`circle ${recording ? "blink" : ""}`}></div>
						<span style={{ marginLeft: "10px" }}>
							{recordedAudioFile
								? "Save Audio"
								: recording
								? "[Stop Recording]"
								: "[Record Audio]"}
						</span>
					</div>
				</button>
			</div>
		</>
	);
};

export default TestYourselfButtons;
